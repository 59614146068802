import React from "react";
import BICC from "../../../Imgs/BICC.png";
import Fenix from "../../../Imgs/Fenix.png";
import Image from "react-bootstrap/esm/Image";
import Stack from "react-bootstrap/esm/Stack";

/**
 * Logos component - Renders logos for BICC and Fenix Insight.
 */
const Logos = () => {
  return (
    <div className="border rounded mt-5 p-3 w-50 mx-auto">
      <Stack>
        <h6 className="text-md-center">
          Developed for the UN by{" "}
          <a href="https://bicc.de" target="_blank">
            BICC
          </a>{" "}
          in colaboration with{" "}
          <a href="https://www.fenix-insight.online/" target="_blank">
            Fenix Insight
          </a>
          .
        </h6>
        <Stack className="mt-5 d-flex flex-column flex-md-row " direction="horizontal" gap={5}>
          <Image src={BICC} alt="Bonn International Centre for Conflict Studies" style={{width:150}} srcset=""  id="image-section" className="img-fluid"/>
          <Image src={Fenix} alt="Fenix Insight" srcset=""  id="image-section" style={{width:150}} className="img-fluid"/>
        </Stack>
      </Stack>
    </div>
  );
};

export default Logos;
