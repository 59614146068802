import { useState } from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import classes from "./ComplainceFilter.module.css";

function ComplainceFilter({ onFilterChange }) {
  const [value, setValue] = useState([]);

  /*
   * The second argument that will be passed to
   * `handleChange` from `ToggleButtonGroup`
   * is the SyntheticEvent object, but we are
   * not using it in this example so we will omit it.
   */
  const handleChange = (val) => {
    setValue(val);
    onFilterChange(val);
  };
  const complaince = ["Shall", "Should", "May", "Must", "Can"];

  return (
    <div className="mt-5 d-flex align-items-baseline">
      <h5 className="me-3">Compliance Check:</h5>
      <ToggleButtonGroup  type="checkbox" value={value} onChange={handleChange}>
        {complaince.map((comp, index) => (
          <ToggleButton
            type="checkbox"
            variant="outline-secondary"
            key={index}
            id={`comp-btn-${index}`}
            className={classes['hover-effect']}
            value={comp}
          >
            {comp}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

export default ComplainceFilter;
