import React from "react";
import Stack from "react-bootstrap/esm/Stack";
import Accordion from "react-bootstrap/Accordion";
const AppliedFilters = (props) => {

  return (
    <div className="mt-5">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header><h5>The following filter/s are applied: </h5></Accordion.Header>
          <Accordion.Body>
            <Stack className="ms-5">
              {props.standards.map((standard, index) => (
                <h6 className="fw-lighter fst-italic" key={index}>
                  {standard}
                </h6>
              ))}
              <span role="button" onClick={props.clearFilter}><h6>Clear filter</h6></span>
            </Stack>
            
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AppliedFilters;
