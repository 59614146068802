import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import classes from "../../../Static/styles.module.css";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import filterClasses from "./Filter.module.css";

import axios from "axios";

const Filter = (props) => {
  const [levels, setLevels] = useState([]);
  const [standards, setStandards] = useState([]);

  // Function to clear selected standards
  const clearSelectedStandards = () => {
    props.setStandardsFilter([]); // Set standardsFilter to an empty array
  };

  // get the filters values to this component
  const handelOnFilterChange = (val) => {
    props.setStandardsFilter(val);
    props.onFilterChange(val);
  };

  useEffect(() => {
    axios
      .get("http://144.217.95.15:8000/client_api/levels/")
      .then((response) => setLevels(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get("http://144.217.95.15:8000/client_api/standards/")
      .then((response) => setStandards(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="mt-5">
      <h5>Filter by Standards:</h5>

      <div className="d-flex justify-content-between mt-2">
        {levels.map((level) => (
          <Dropdown key={level.id}>
            <Dropdown.Toggle
              id={level.id}
              className={classes["level-" + level.levelNumber]}
            >
              {level.levelName}
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              <ToggleButtonGroup
                type="checkbox"
                value={props.standardsFilter}
                onChange={handelOnFilterChange}
                vertical
                name={level.levelNumber}
              >
                {standards
                  .filter(
                    (standard) => standard.standardLevel === level.levelNumber
                  )
                  .map((standard, index) => (
                    <ToggleButton
                      name="std"
                      type="checkbox"
                      variant="outline-secondary"
                      key={standard.id}
                      id={`std-btn-${standard.id}`}
                      className={classes[`list-items-${standard.standardLevel}`]}
                      value={standard.standardTitle}
                    >
                      {standard.standardTitle}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </Dropdown.Menu>
          </Dropdown>
        ))}
      </div>
    </div>
  );
};

export default Filter;
