import React, { useState } from "react";
import Card from "../../UI/Card/Card";
import Stack from "react-bootstrap/Stack";
import ResultsItem from "./ResultsItem";
import ResultModal from "../../UI/ResultModal/ResultModal";
import ResultsPagination from "../../UI/ResultsPagination/ResultsPagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Results = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = props.currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPost = props.results.slice(firstPostIndex, lastPostIndex);
  const resultsLength = props.results.length;

  const handleShowModal = (result) => {
    setSelectedResult(result);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedResult(null);
  };

  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <span>{children}</span>
    </OverlayTrigger>
  );

  return (
    <div className="mt-5 mb-5">
      <Card className="secondary p-1">
        <h2 className="text-secondary text-center">{props.searchedPhrase}</h2>

        <blockquote class="blockquote">
          <p class="mb-3">
            Search Results: <strong>{resultsLength}</strong> Results Found at
            Score <strong>{props.score}</strong>{" "}
            <Link title="Our search tool uses a scoring system that starts at 8. If no results are found, the score may decrease and the search will be reattempted. A lower score can result in more diverse results, some of which may be less relevant." id="t-1">
              ( ? )
            </Link>
          </p>
          <footer class="blockquote-footer">
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Learn more
            </a>{" "}
            about the scoring system
          </footer>
        </blockquote>

        <Stack gap={3} className="mt-3">
          {currentPost.map((result) => (
            <ResultsItem
              onClick={() => handleShowModal(result)}
              key={result.Index}
              level={result.Level}
              title={result.Title}
              heading1={result.Heading1}
              paragraph={result.Paragraph}
              complainceFilter={props.complainceFilter}
              pageNumber={result.PageNum}
            ></ResultsItem>
          ))}
        </Stack>
        <ResultsPagination
          totalPosts={props.results.length}
          postsPerPage={postsPerPage}
          setCurrentPage={props.setCurrentPage}
          currentPage={props.currentPage}
        ></ResultsPagination>
      </Card>

      <ResultModal
        show={showModal}
        handleClose={handleCloseModal}
        level={selectedResult ? selectedResult.Level : ""}
        module={selectedResult ? selectedResult.Module : ""}
        heading1={selectedResult ? selectedResult.Heading1 : ""}
        heading2={selectedResult ? selectedResult.Heading2 : ""}
        heading3={selectedResult ? selectedResult.Heading3 : ""}
        heading4={selectedResult ? selectedResult.Heading4 : ""}
        pageNumber={selectedResult ? selectedResult.PageNum : ""}
        paragraph={selectedResult ? selectedResult.Paragraph : ""}
        sentence={selectedResult ? selectedResult.Sentence : ""}
        highlight={selectedResult ? selectedResult.Highlight : ""}
      ></ResultModal>
    </div>
  );
};

export default Results;
