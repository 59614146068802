import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
//import classes from "./input.module.css"

const Input = (props) => {
  return (
    <InputGroup size="lg">
      <Form.Control
        placeholder={props.placeholder}
        type={props.type}
        id={props.id}
        name={props.name}
        aria-label={props["aria-label"]}
        aria-describedby={props["aria-describedby"]}
        onChange={props.onChange}
      />
    </InputGroup>
  );
};

export default Input;
