import React, { useState } from "react";
import classes from "./ResultsPagination.module.css";
import Button from "react-bootstrap/esm/Button";

const ResultsPagination = ({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
}) => {

  
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleFirstbtn = () => {
    setCurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);

  };

  const handleLastbtn = () => {
    setCurrentPage(pages.slice(-1));
    setmaxPageNumberLimit(Math.floor(pages.slice(-1) / 5) * 5);
    setminPageNumberLimit(Math.floor(pages.slice(-1) / 5) * 5 - 5);
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <p> &hellip; </p>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <p> &hellip; </p>;
  }

  return (
    <div className={classes.pagination}>
      <Button onClick={handleFirstbtn} variant="primary">
        {"<<"}
      </Button>
      <Button
        onClick={handlePrevbtn}
        disabled={currentPage == pages[0] ? true : false}
        variant="secondary"
      >
        Prev
      </Button>
      {pageDecrementBtn}
      {pages.map((page, index) => {
        if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
          return (
            <Button
              key={index}
              onClick={() => setCurrentPage(page)}
              className={page == currentPage ? classes.active : ""}
            >
              {page}
            </Button>
          );
        }
      })}
      {pageIncrementBtn}
      <Button
        onClick={handleNextbtn}
        disabled={currentPage == pages[pages.length - 1] ? true : false}
        variant="secondary"
      >
        Next
      </Button>
      <Button onClick={handleLastbtn} variant="primary">
        {">>"}
      </Button>
    </div>
  );
};

export default ResultsPagination;
