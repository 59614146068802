import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import classes from "../../../Static/styles.module.css";
import sbd from 'sbd';
import { Interweave } from 'interweave';
import parse from 'html-react-parser';

const ResultModal = (props) => {

  const test_text = "This is a <strong>test text</strong>"

  const HighlightSubstring = ({ text, substring }) => {
    const highlightStyle = {
      backgroundColor: "yellow",
      fontWeight: "bold",
    };

    // Split the text into sentences based on the substring
    const sentences = sbd.sentences(text);
    
    // Wrap the matching sentences with a span and apply the highlight style
    const highlightedText = sentences.map((sentence, index) => {
      if (sentence == substring) {
        return (
          <span key={index} style={highlightStyle}>
            {sentence}
          </span>
        );
      }
      return sentence;
    });
    return highlightedText;
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      onHide={props.handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <div className={classes[`level-text-${props.level}`]}>
            Level {props.level}
          </div>
          <div className="mb-3">IDDRS - {props.module}</div>
          <p className="fs-5 fw-light mb-0">{props.heading1}</p>
          <p className="fs-6 fw-light mb-0">{props.heading2}</p>
          <p className="fs-6 fw-light mb-0">{props.heading3}</p>
          <p className="fs-6 fw-light mb-0">{props.heading4}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <Interweave content={props.highlight} />

        <div className="fs-5 fw-light mt-4">
          <p className="text-end">Page: {props.pageNumber}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResultModal;
