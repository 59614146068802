import React, { useState } from "react";
import Input from "../../UI/Input/Input";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";

const Search = (props) => {
  return (
    <div className="mt-5">
      <Form
        id="searchForm"
        method="get"
        className="d-flex align-items-center"
        onSubmit={props.onSubmit}
      >
        <Input
          placeholder="Search IDDRS..."
          type="text"
          id="search_input"
          name="phrase"
          aria-label="Seach IDDRS..."
          aria-describedby="basic-addon1"
          onChange={props.onChange}
        ></Input>
        <Button id="submitSearch" type="submit" variant="outline-secondary" size="lg">Search</Button>

      </Form>
    </div>
  );
};

export default Search;
