import React, { Fragment, useState } from "react";
import WordCloud from "react-d3-cloud";
import Card from "react-bootstrap/Card";
import classes from "./WordCloud_.module.css";
const data = [
  {
    text: "Disarmament",
    value: 68.0,
  },
  {
    text: "Demobilization",
    value: 60.0,
  },
  {
    text: "Reintegration support",
    value: 58.0,
  },
  {
    text: "Reinsertion",
    value: 57.0,
  },
  {
    text: "Security Sector",
    value: 52.0,
  },
  {
    text: "DDR participants",
    value: 51.0,
  },
  {
    text: "Integrated Assessments",
    value: 48.0,
  },
  {
    text: "Community Violence Reduction",
    value: 46.0,
  },
  {
    text: "Transitional Weapons and Ammunition Management",
    value: 43.0,
  },
  {
    text: "Children associated with armed forces and groups",
    value: 42.0,
  },
  {
    text: "Community based reintegration",
    value: 40.0,
  },
  {
    text: "DDR-related tools",
    value: 37.0,
  },
  {
    text: "DDR Programme",
    value: 31.0,
  },
  {
    text: "DDR Process",
    value: 27.0,
  },
  {
    text: "Foreign combatants",
    value: 26.0,
  },
  {
    text: "Pre-DDR",
    value: 22.0,
  },
  {
    text: "DDR policy",
    value: 19.0,
  },
  {
    text: "DDR Strategy",
    value: 17.0,
  },
  {
    text: "Eligibility criteria",
    value: 13.0,
  },
  {
    text: "Preventing recruitment",
    value: 13.0,
  },
];

const WordCloud_ = ({ handleButtonClick }) => {
  const handleClick = (value) => {
    handleButtonClick(value);
  };

  return (
    <Card className="w-50">
      <h4 className="text-decoration-underline text-center">
        Optional Search Terms
      </h4>
      <div className={classes.pointer}>
        <WordCloud
          width={600}
          data={data}
          font="Times"
          fontStyle="italic"
          //fontWeight="bold"
          fontSize={(word) => Math.log2(word.value) * 5}
          //fontSize={33}
          spiral="rectangular"
          rotate={0}
          fill="gray"
          padding={2}
          onWordClick={(event, d) => {

            handleClick(d.text);
          }}
        />
      </div>
    </Card>
  );
};

export default WordCloud_;
